




    import 'reflect-metadata';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class URL extends Vue {
        @Prop() public readonly to!: string;
    }
