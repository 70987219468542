<template>
  <div id="app">
    <el-container direction="vertical">
      <el-header
        height=""
        style="height: 120px; line-height: 120px"
        :class="isTopBarDark ? 'top-bar-dark' : 'top-bar-light'"
      >
        <div class="main-limiter-2">
          <div id="nav">
            <!--                        <router-link to="/404">Projects</router-link>-->
            <router-link to="/about-us">About us</router-link>
            <router-link to="/articles">Insights</router-link>
            <router-link to="/webinars">Webinars</router-link>
            <router-link to="/foundation">Foundation</router-link>
            <a href="https://airev.traffit.com/career/" target="_blank"
              >Career</a
            >
            <router-link to="/platform">Platform</router-link>

            <router-link to="/contact-us" class="menu-outline"
              >Contact Us</router-link
            >
          </div>
          <div id="nav-mobile" :class="{ 'top-bar-dark': isTopBarDark }">
            <div class="nav-mobile-button" @click="toggleNavMenu">☰</div>
            <div
              :class="{
                'nav-mobile-menu': true,
                'nav-mobile-menu-open': isNavMobileMenuOpen,
                'nav-mobile-dark': isTopBarDark,
              }"
            >
              <router-link to="/about-us">About us</router-link>
              <router-link to="/articles">Insights</router-link>
              <router-link to="/webinars">Webinars</router-link>
              <router-link to="/foundation">Foundation</router-link>
              <a href="https://airev.traffit.com/career/" target="_blank"
                >Career</a
              >
              <router-link to="/platform">Platform</router-link>
              <router-link to="/contact-us">Contact Us</router-link>
            </div>
          </div>
          <div id="logo">
            <router-link to="/" style="margin-bottom: 6px">
              <DoroImage
                v-if="!isTopBarDark"
                :src="ImageLogoAIRev"
                max-width="168px"
                square
                simple
                loading="auto"
              ></DoroImage>
              <DoroImage
                v-else
                :src="ImageLogoWhiteAIRev"
                max-width="168px"
                square
                simple
                loading="auto"
              ></DoroImage>
            </router-link>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer :height="null" style="padding: 53px 0 0">
        <div class="main-limiter-2">
          <DoroImage
            :src="ImageLogoWhiteAIRev"
            max-width="262px"
            square
            simple
            loading="auto"
          ></DoroImage>
          <el-row style="padding-top: 53px">
            <el-col :sm="9" :xs="24">
              <div>
                <!--                          <div class="footer-icon">-->
                <!--                            <DoroImage :src="IconPhone" max-width="24px" square simple loading="auto"></DoroImage>-->
                <!--                          </div>-->
                <!--                          <div class="footer-icon-text">-->
                <!--                            <div class="footer-icon-text-title">Call Us</div>-->
                <!--                            <div class="footer-icon-text-content">+1 646 213 7667</div>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                        <div style="margin:37px 0;">-->
                <div class="footer-icon">
                  <DoroImage
                    :src="IconMail"
                    max-width="24px"
                    square
                    simple
                    loading="auto"
                  ></DoroImage>
                </div>
                <div class="footer-icon-text">
                  <div class="footer-icon-text-title">Mail Us</div>
                  <div class="footer-icon-text-content">contact@airev.us</div>
                </div>
              </div>
            </el-col>
            <el-col :sm="5" :xs="12">
              <router-link to="/about-us" class="footer-link"
                >About Us</router-link
              >
              <router-link to="/foundation" class="footer-link"
                >AI REV Foundation</router-link
              >
              <router-link to="/contact-us" class="footer-link"
                >Contact</router-link
              >
            </el-col>
            <el-col :sm="5" :xs="12">
              <router-link to="/articles" class="footer-link"
                >AI Articles</router-link
              >
              <router-link to="/webinars" class="footer-link"
                >Webinars</router-link
              >
              <a
                href="https://airev.traffit.com/career/"
                target="_blank"
                class="footer-link"
                >Career in AI</a
              >
            </el-col>
            <el-col :sm="5" :xs="24">
              <div
                style="
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 26px;
                  padding-bottom: 20px;
                "
              >
                Follow us on social media
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/ai-rev/"
                  target="_blank"
                  style="margin: 0 20px 0 0"
                >
                  <DoroImage
                    :src="logoLinkedIn"
                    max-width="50px"
                    square
                    simple
                    style="border-radius: 8px"
                  ></DoroImage>
                </a>
                <a
                  href="https://www.facebook.com/AI-Revolution-103702891022983"
                  target="_blank"
                >
                  <DoroImage
                    :src="logoFacebook"
                    max-width="50px"
                    square
                    simple
                    style="border-radius: 8px"
                  ></DoroImage>
                </a>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="footer-border" style="margin-top: 20px">
          <div class="footer-go-up">
            <div class="footer-go-up-in">
              <div class="footer-go-up-icon" @click="scrollToTop">
                <DoroImage
                  :src="IconArrowUP"
                  max-width="30px"
                  square
                  simple
                  loading="auto"
                ></DoroImage>
              </div>
            </div>
          </div>
        </div>
        <div class="main-limiter-2">
          <div
            style="
              padding: 30px 0;
              line-height: 30px;
              text-align: center;
              font-size: 16px;
              font-weight: 500;
            "
          >
            Copyright AI REV © {{ currentYear }}. All rights reserved.
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import DoroImage from "@/components/DoroImage";
import URL from "@/components/URL";

import ImageLogoAIRev from "@/assets/logos/airev-logo.svg";
import ImageLogoWhiteAIRev from "@/assets/logos/airev-logo-white.svg";

import logoFacebook from "@/assets/graphics/facebook.png";
import logoLinkedIn from "@/assets/graphics/linkedin2.png";

import IconPhone from "@/assets/icons/phone.svg";
import IconMail from "@/assets/icons/mail.svg";

import IconArrowUP from "@/assets/icons/arrowup.svg";

@Component({
  components: {
    DoroImage,
    URL,
  },
  metaInfo() {
    return {
      title: null,
      titleTemplate: "AI REV - %s",
      htmlAttrs: {
        lang: "en",
      },
    };
  },
})
export default class App extends Vue {
  get ImageLogoAIRev() {
    return ImageLogoAIRev;
  }
  get ImageLogoWhiteAIRev() {
    return ImageLogoWhiteAIRev;
  }

  get logoFacebook() {
    return logoFacebook;
  }
  // get logoTwitter() { return logoTwitter; }
  get logoLinkedIn() {
    return logoLinkedIn;
  }

  get IconPhone() {
    return IconPhone;
  }
  get IconMail() {
    return IconMail;
  }

  get IconArrowUP() {
    return IconArrowUP;
  }

  get isTopBarDark() {
    const m = this.$route.matched;
    console.log("m", m);
    return m.length > 0 && !!m[m.length - 1].props?.default?.darkTopBar;
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  isNavMenuOpen = false;

  toggleNavMenu() {
    this.isNavMenuOpen = !this.isNavMenuOpen;
  }

  get isNavMobileMenuOpen() {
    return this.isNavMenuOpen;
  }
}
</script>

<style lang="scss">
$background: white;
$My-Color-Theme-1-hex: #13541c;
$My-Color-Theme-2-hex: #17861e;
$My-Color-Theme-3-hex: #1e882e;
$My-Color-Theme-4-hex: #519b5c;
$My-Color-Theme-5-hex: rgba(23, 179, 44, 0.51);

$Text-Color-Theme-normal: #3d3d3d;
$Text-Color-Theme-header: #262626;
$Text-Color-Theme-gray: #6b6b6b;

$Basic-Size: 19px;

$Media-Tablet-Size: 940px;
$Media-Mobile-Size: 810px;
$Media-Phone-Size: 650px;

html,
body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100%;
  /*max-width: 100vw;*/
  /*overflow-x: hidden;*/
}

html {
  background: $background;
}

body {
  background: transparent;
  overflow: auto !important; //HACK: for https://github.com/ElemeFE/element/pull/18746 //TODO:remove
}

.el-main {
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "SF Pro Icons",
    Roboto, Oxygen-Sans, "Segoe UI", Ubuntu, Cantarell, "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
}

.main-divider {
  margin-top: 5em;
}

$Max-Page-Width: 1050px;
$Max-Page-Width-2: 1150px;

.main-limiter {
  width: 100%;
  max-width: $Max-Page-Width;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  article,
  &.small-limiter {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  &.big-limiter {
    max-width: 1200px;
  }
}
header,
footer {
  .main-limiter {
    max-width: $Max-Page-Width;
  }
}

.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.custom-list-item {
  font-weight: 600;
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}
.custom-list-item2 {
  font-weight: 600;
  background-color: #fcfcfc;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.main-limiter-2 {
  width: 100%;
  max-width: $Max-Page-Width-2;
  margin: 0 auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
  .el-footer {
    .main-limiter-2 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

@media only screen and (max-width: $Media-Tablet-Size) {
  .hide-tablet {
    display: none;
  }
}

@media only screen and (max-width: $Media-Mobile-Size) {
  .hide-mobile {
    display: none;
  }
}

@media only screen and (max-width: $Media-Phone-Size) {
  .hide-phone {
    display: none;
  }
}

.el-header {
  //margin: 1.382em 0;
  height: 1.618em;
  line-height: 40px;
  font-size: 12px;

  .main-limiter {
    max-width: $Max-Page-Width-2;
  }

  #logo {
    cursor: pointer;
    overflow: hidden;
    //max-height: 85px;
    font-size: 24px;
    font-weight: 100;
    //line-height: 42px;
    display: inline-block;

    a {
      color: black;
      text-decoration: none;
      outline: none;
      line-height: 42px;
      height: 42px;
      display: inline-block;
      //margin-top: 21px;
      vertical-align: middle;

      .doroimage {
        height: 100%;
      }
    }
  }

  #nav {
    float: right;
    height: 120px;
    line-height: 120px;
    //line-height: 83px;

    a {
      color: $My-Color-Theme-1-hex;
      transition: color 0.3s ease, border-color 0.3s ease;
      text-transform: uppercase;
      text-decoration: none;
      //letter-spacing: 0.0955em;
      letter-spacing: 0.01em;
      //font-size: 1.191em;
      font-size: 16px;
      //padding: 10.5px;
      padding: 19px;
      //margin-left: 0.382em;
      //border: 1px $background solid;
      //border-radius: 1.618em;
      font-weight: 400;

      font-smooth: auto;
      -webkit-font-smoothing: auto;
      -moz-osx-font-smoothing: auto;

      &.menu-outline {
        color: white;
        //border-color: $My-Color-Theme-2-hex;
        background: $My-Color-Theme-2-hex;
        border: 1px $My-Color-Theme-2-hex solid;
        //border-radius: 1.618em;
        border-radius: 50px;
        padding: 19px 29px;
        margin-left: 8px;
        font-weight: 700;
      }

      &.router-link-exact-active {
        color: $My-Color-Theme-1-hex;
        border-color: rgba(23, 179, 44, 0.2);

        &.menu-outline {
          color: white;
        }
      }

      &:hover {
        //color: $My-Color-Theme-3-hex;
        //border-color: $My-Color-Theme-3-hex;
      }
    }
  }

  #nav-mobile {
    display: none;
  }

  &.top-bar-dark {
    background: black;

    #logo a,
    #nav a {
      color: white;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .el-header #nav {
    a {
      padding-left: 9px;
      padding-right: 9px;
    }

    .menu-outline {
      padding: 19px 8px !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .el-header #nav {
    a {
      padding-left: 5px;
      padding-right: 5px;
    }

    .menu-outline {
      padding: 17px 5px !important;
    }
  }
}

@media only screen and (max-width: 860px) {
  .el-header #nav {
    a {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 14px;
    }

    .menu-outline {
      padding: 15px 4px !important;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .el-header {
    margin: 0;
    padding: 0;
    z-index: 9999;
    overflow: visible;

    .main-limiter-2 {
      padding: 0 20px;
    }

    #nav-mobile {
      display: block;
      float: right;
      height: 120px;
      line-height: 120px;
      font-size: 32px;
      color: black;
      cursor: pointer;

      &.top-bar-dark {
        color: white;
      }

      .nav-mobile-menu {
        display: none;
        position: absolute;
        top: 120px;
        right: 0;

        a {
          display: block;
          padding: 10px 20px;
          background: black;
          color: white;
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          border-bottom: 1px solid #333;
          width: 100vw;
          max-width: 300px;
          line-height: 50px;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .nav-mobile-menu-open {
        display: block !important;
      }
    }

    #nav {
      display: none;
      line-height: normal;
      position: absolute;
      padding: 0.6em 0.6em 0 0;
      right: 0;
      top: 0em;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 4em;

      &::before {
        content: "☰";
        font-size: 3em;
        padding: 0.15em 0.7em 0 0;
        float: right;
      }

      a {
        display: none;
        background: rgba(255, 255, 255, 0.8);
        z-index: 999;
        margin: 10px 0;
        height: 2em;
        padding: 0 1em;
        font-size: 2em;
        line-height: 2em;
        text-align: right;

        &:first-child {
          margin-top: 2.5em;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:hover {
        a {
          display: block;
        }
      }
    }

    .main-limiter {
      padding: 0;
    }
  }
}

footer {
  background: #115f15;
  color: white;
  font-size: 15px;
  font-weight: 300;

  .footer-icon {
    width: 67px;
    height: 67px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;

    border-radius: 50%;
    border: 1px solid #989898;
    float: left;
  }

  .footer-icon-text {
    padding: 7px 0 7px 83px;

    .footer-icon-text-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
    }
    .footer-icon-text-content {
      padding-top: 3px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }
  }

  .footer-link {
    color: white;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    display: block;
    text-decoration: none;
    padding-bottom: 20px;
  }

  .footer-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    height: 1px;
    position: relative;

    .footer-go-up {
      position: absolute;
      top: -34px;
      left: 0;
      right: 0;
      width: 100%;
      height: 69px;

      .footer-go-up-in {
        width: 100%;
        max-width: $Max-Page-Width-2;
        margin: 0 auto;

        .footer-go-up-icon {
          width: 69px;
          height: 69px;
          float: right;
          text-align: center;
          align-items: center;
          justify-content: center;
          display: flex;

          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background: #00121e;

          cursor: pointer;
        }
      }
    }
  }
}
.reports-section {
  margin-left: -70px;
  margin-right: -70px;
  display: flex;
}

.single-report {
  width: 50%;
}

.icon-report {
  max-width: 22px;
  margin-bottom: -4px;
}

@media only screen and (max-width: 1100px) {
  .reports-section {
    margin-left: -10px;
    margin-right: -10px;
    text-align: center;
    display: block;
  }
  .single-report {
    width: 100%;
  }
}

.el-main {
  padding-top: 0px;
  padding: 0;
}

article,
main {
  font-size: $Basic-Size;
  line-height: 1.618em;
  font-weight: 300;
  color: $Text-Color-Theme-normal;

  a {
    //color: $My-Color-Theme-3-hex;
    color: #409eff;
    text-decoration: none;
    font-weight: 400;
    &:hover,
    &:active {
      //color: $My-Color-Theme-1-hex;
      color: #06c;
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  blockquote {
    color: $Text-Color-Theme-header;
    font-weight: 300;
    font-variant-ligatures: common-ligatures;
    line-height: 1.382em;
  }
  h1,
  h2,
  h3 {
    margin: 1.618em 0 1em;
  }
  h1 {
    text-align: center;
    font-size: 2.674em;
  }
  h2 {
    font-size: 1.618em;
  }
  h3 {
    font-size: 1.382em;
  }
  blockquote {
    font-size: 1.191em;
    text-align: center;
    margin-block-start: 1.382em;
    margin-block-end: 1.382em;
    margin-left: 0.382em;
    margin-right: 0.382em;
    font-style: italic;
    font-weight: 300;

    &.bigger {
      font-size: 1.382em;
    }
  }

  ul li {
    margin-bottom: 0.191em;
  }
}

b {
  // "Represents a span of text to be stylistically offset from the normal prose without conveying any extra importance"
  font-weight: 500;
}

strong {
  // "Represents strong importance for its contents"
  font-weight: 600;
}

.text-bold {
  font-weight: 400;
  font-size: 0.9em;

  strong {
    font-weight: 600;
  }
}

.text-center {
  text-align: center;
}

.display-block {
  display: block;
}

@media only screen and (max-width: 767px) {
  .el-row-xs-wrap {
    flex-wrap: wrap;
  }
  .el-col-xs-break {
    flex-basis: 100%;
  }
}

.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-0a {
  margin-bottom: 0.382em;
}
.margin-bottom-0b {
  margin-bottom: 0.618em;
}
.margin-bottom-1 {
  margin-bottom: 1em;
}
.margin-bottom-1a {
  margin-bottom: 1.382em;
}
.margin-bottom-1b {
  margin-bottom: 1.618em;
}
.margin-bottom-2 {
  margin-bottom: 2em;
}
.margin-bottom-3 {
  margin-bottom: 3em;
}
.margin-bottom-4 {
  margin-bottom: 4em;
}
.margin-bottom-5 {
  margin-bottom: 5em;
}
.margin-bottom-6 {
  margin-bottom: 6em;
}
.margin-bottom-7 {
  margin-bottom: 7em;
}
.margin-bottom-8 {
  margin-bottom: 8em;
}
.margin-bottom-9 {
  margin-bottom: 9em;
}
.margin-bottom-10 {
  margin-bottom: 10em;
}

.margin-top-0 {
  margin-top: 0;
}
.margin-top-0a {
  margin-top: 0.382em;
}
.margin-top-0b {
  margin-top: 0.618em;
}
.margin-top-1 {
  margin-top: 1em;
}
.margin-top-1a {
  margin-top: 1.382em;
}
.margin-top-1b {
  margin-top: 1.618em;
}
.margin-top-2 {
  margin-top: 2em;
}
.margin-top-3 {
  margin-top: 3em;
}
.margin-top-4 {
  margin-top: 4em;
}
.margin-top-5 {
  margin-top: 5em;
}
.margin-top-6 {
  margin-top: 6em;
}
.margin-top-7 {
  margin-top: 7em;
}
.margin-top-8 {
  margin-top: 8em;
}
.margin-top-9 {
  margin-top: 9em;
}
.margin-top-10 {
  margin-top: 10em;
}

@media only screen and (max-width: 767px) {
  .margin-top-xs-0 {
    margin-top: 0;
  }
  .margin-top-xs-0a {
    margin-top: 0.382em;
  }
  .margin-top-xs-0b {
    margin-top: 0.618em;
  }
  .margin-top-xs-1 {
    margin-top: 1em;
  }
  .margin-top-xs-1a {
    margin-top: 1.382em;
  }
  .margin-top-xs-1b {
    margin-top: 1.618em;
  }
  .margin-top-xs-2 {
    margin-top: 2em;
  }
  .margin-top-xs-3 {
    margin-top: 3em;
  }
  .margin-top-xs-4 {
    margin-top: 4em;
  }
  .margin-top-xs-5 {
    margin-top: 5em;
  }
  .margin-top-xs-6 {
    margin-top: 6em;
  }
  .margin-top-xs-7 {
    margin-top: 7em;
  }
  .margin-top-xs-8 {
    margin-top: 8em;
  }
  .margin-top-xs-9 {
    margin-top: 9em;
  }
  .margin-top-xs-10 {
    margin-top: 10em;
  }
}

.hack-no-margin-left-right {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/** {*/
/*  background: rgba(120,120,120,0.05);*/
/*}*/

p.smaller-text {
  font-size: 0.9em;
  line-height: 1.382em;
}

.shadow {
  box-shadow: -5px 19px 26px rgba(222, 222, 222, 0.79);
}
.round-corners {
  border-radius: 1em;
}

.doroimage {
  max-width: 100%;

  img {
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  &.inside-of-content {
    display: block;
    padding-bottom: 0.382em;
    margin: 0 auto;
  }

  &.inline {
    display: inline-block;
  }

  &.round-corners {
    border-radius: 1em;

    img {
      border-radius: 1em;
      height: auto;
    }
  }

  &.centered-vertically img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.shadow {
    box-shadow: -5px 19px 26px rgba(222, 222, 222, 0.79);

    &.inside-of-content {
      padding-bottom: 0;
      margin-bottom: 0.382em;
    }
  }

  &.hack-rendering img {
    image-rendering: -webkit-optimize-contrast;
  }

  .image-caption {
    font-size: 0.8em;
    text-align: right;
  }
}

@media only screen and (max-width: 767px) {
  .doroimage.shadow {
    box-shadow: none; //temp hack
  }
}

.dorobutton {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  font-weight: 400;
  -webkit-transition: 0.1s;
  transition: 0.1s;

  background: #fff;
  border: 1px solid #17861e;
  color: #17861e;
  padding: 0.5em 1em;
  border-radius: 2em;

  .btn-text {
    font-size: 18px;
    letter-spacing: 0.01em;
  }
}

.article-card {
  width: 100%;
  min-height: 107px;
  margin-bottom: 2em;
  color: $Text-Color-Theme-normal;
  font-weight: 300;

  .card-texts {
    position: relative;
    display: inline-block;
    max-width: calc(100% - 200px - 30px - 2px);

    .card-info {
      font-size: 0.812em;
      line-height: 0.812em;
      font-weight: 400;
      color: $Text-Color-Theme-gray;
    }

    .card-title {
      margin-top: 0.2em;
      padding-bottom: 0;
      font-weight: 400;
      color: $Text-Color-Theme-header;
    }

    .card-description {
      font-size: 0.9em;
      line-height: 1.5em;
    }
  }

  .card-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 200px;
    height: 105px;
    border: 1px solid #d2d2d7;
    margin-right: 30px;
    display: inline-block;
    float: left;
    border-radius: 1em;
  }
}

@media only screen and (max-width: 767px) {
  .article-card {
    .card-texts {
      max-width: 100%;
      text-align: center;
      margin-top: 0.6em;
    }

    .card-image {
      float: unset;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
