<template>
  <div class="webinar-desc">
    <section>
      <h4 class="section-title">About this Event</h4>
      <p class="section-body">
        A webinar hosted by Marek Bardoński, who at the age of 19 was already awarded for his scientific work by Harvard and NASA. Marek has also worked in NVIDIA HQ, Microsoft HQ, and NVIDIA Switzerland. At the age of 26 he was already generating multi-million returns from AI projects. He is now a Managing Partner and CEO of an AI consulting company (AI REV). He also runs a philanthropy foundation (AIT Philanthropy) which focuses on helping talented underprivileged youth from the Philippines by organising tutoring programs.
      </p>
      <p class="section-body">
        At this webinar, Marek Bardoński will talk about the practical applications of artificial intelligence in marketing, in particular, how beneficial implementing AI can be for:
      </p>
        <ul>
          <li>churn prediction,</li>
          <li>predictive lead generation,</li>
          <li>dynamic pricing,</li>
          <li>fraud protection</li>
          <li>behavioral profiling.</li>
        </ul>
      <p class="section-body">
        As practice shows, large and medium-sized companies achieve 10x + return on investment after implementing AI in these areas. Predictive churn prediction alone can increase revenues by 5% to 10% on average, by applying AI-driven customer activation and education strategies.
      </p>
      <p class="section-body">
        The webinar is dedicated to decision makers of any industry, both B2B and B2C types of businesses, interested in understanding if the above techniques are relevant to their business units.
      </p>
      <p class="section-body">
        The webinar will consist of 45 minutes of presentation and 15 minutes of Q&A session when Marek will answer questions from the audience.

      </p>
    </section>
    <section>
      <h4 class="section-title">About AI REV</h4>
      <p class="section-body">
        AI REV is a boutique AI consulting company formed with partners having practical AI experience and a strong track record, who provide state-of-the-art consulting & project delivery for enterprise clients. Our goal is to reduce the average work hours of humans from 40h to 20h. We achieve it by augmenting companies with AI-powered efficiency improvements.

      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ApplicationOfAIForMarketing',
}
</script>

<style lang="scss">
.webinar-desc {
  .section-title {
    font-size: 18px;
    font-weight: 500;
  }

  .section-body {
    font-size: 18px;
    font-weight: 300;
  }
}
</style>
