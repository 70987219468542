import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import webinars from "../data/webinars";

Vue.use(VueRouter);
Vue.use(RouterPrefetch);

// console.log(webinars)

const routes = [
  {
    path: "/",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "../views/home.vue"),
    props: {
      darkTopBar: true,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () =>
      import(/* webpackChunkName: "about-us" */ "../views/about-us.vue"),
  },
  {
    path: "/platform",
    name: "platform",
    component: () =>
      import(/* webpackChunkName: "contact-us" */ "../views/platform.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () =>
      import(/* webpackChunkName: "contact-us" */ "../views/contact-us.vue"),
  },
  {
    path: "/foundation",
    name: "foundation",
    component: () =>
      import(/* webpackChunkName: "foundation" */ "../views/foundation.vue"),
  },
  {
    path: "/articles",
    name: "articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/articles.vue"),
  },
  {
    path: "/ai-in-trading-and-retail",
    name: "ai-in-trading-and-retail",
    component: () =>
      import(
        /* webpackChunkName: "ai-in-trading-and-retail" */ "../views/ai-in-trading-and-retail.vue"
      ),
  },
  {
    path: "/artificial-intelligence-in-supply-chain-management",
    name: "artificial-intelligence-in-supply-chain-management",
    component: () =>
      import(
        /* webpackChunkName: "artificial-intelligence-in-supply-chain-management" */ "../views/artificial-intelligence-in-supply-chain-management.vue"
      ),
  },
  {
    path: "/explainable-ai",
    name: "explainable-ai",
    component: () =>
      import(
        /* webpackChunkName: "explainable-ai" */ "../views/explainable-ai.vue"
      ),
  },
  {
    path: "/explainer-dashboard",
    name: "explainer-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "explainer-dashboard" */ "../views/explainer-dashboard.vue"
      ),
  },
  {
    path: "/ultimate-guide-to-natural-language-processing-courses",
    name: "ultimate-guide-to-natural-language-processing-courses",
    component: () =>
      import(
        /* webpackChunkName: "ultimate-guide-to-natural-language-processing-courses" */ "../views/ultimate-guide-to-natural-language-processing-courses.vue"
      ),
  },
  {
    path: "/lorem-ipsum",
    name: "lorem-ipsum",
    component: () =>
      import(/* webpackChunkName: "lorem-ipsum" */ "../views/lorem-ipsum.vue"),
  },
  {
    path: "/webinars",
    name: "webinars",
    component: () => import("../views/webinar-list.vue"),
    props: {
      webinars: webinars,
    },
  },
  ...webinars.map((w) => ({
    path: `/webinar/${w.id}`,
    name: `Webinar: ${w.title}`,
    component: () => import("../views/single-webinar.vue"),
    props: {
      webinar: w,
    },
  })),
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
