import XaiAppsWebinarContent from "@/data/webinarContent/XaiAppsWebinarContent.vue";
import ApplicationOfAIForMarketing from "@/data/webinarContent/ApplicationOfAIForMarketing.vue";
import HandlingEnterpriseData from "@/data/webinarContent/HandlingEnterpriseData.vue";
import AddingExplainability from "@/data/webinarContent/AddingExplainability.vue";

export type Webinar = {
    id: string;
    mainImage: string;
    title: string;
    summary: string;
    upcoming: boolean;
    dateTime: Date;
    content: any;
}

interface IDate {dateTime: Date, length: number}

const GenerateUpcoming = <T extends IDate>(w: T): T & {upcoming: boolean, endDateTime: Date} => ({
    ...w,
    upcoming: w.dateTime.toISOString() > new Date().toISOString(),
    endDateTime: new Date(Date.parse(w.dateTime.toISOString()) + w.length * 60 * 60 * 1000)
})

const DateFromString = (s: string) => {
    console.log(s)
    console.log(Date.parse(s))
    return new Date(Date.parse(s));
}

const webinars: Webinar[] = [
    // {
    //     summary: "",
    //     id: 'adding-explainability',
    //     title: 'Adding „Explainability” to existing Machine Learning models',
    //     mainImage: 'main.jpg',
    //     dateTime: DateFromString("2022-06-22T17:00:00+00:00"),
    //     length: 1.0,
    //     content: AddingExplainability,
    //     upcoming: true,
    //     performer: 'Joaquin Marques'
    // },
    {
        summary: "Presenting use cases and best practices for monitoring, scaling and handling enterprise data to improve business performance.",
        id: 'handling-enterprise-data',
        title: 'Best practices for monitoring, scaling and handling enterprise data to improve business performance',
        mainImage: 'main.jpg',
        dateTime: DateFromString("2022-05-11T17:00:00+00:00"),
        length: 1.0,
        content: HandlingEnterpriseData,
        performer: 'Matt Doroszko'
    },
    {
        summary: "Explaining how beneficial implementing AI can be for: churn prediction, predictive lead generation, dynamic pricing, fraud protection and behavioral profiling.",
        id: 'practical-application-of-ai-for-marketing-and-customer-relationships',
        title: 'The Practical Application of AI for Marketing and Customer Relationships',
        mainImage: 'main.png',
        dateTime: DateFromString("2022-03-09T18:00:00+00:00"),
        length: 1.0,
        content: ApplicationOfAIForMarketing,
        performer: 'Marek Bardoński'
    },
    {
        summary: "This webinar aims to explain the most common Explainable AI (XAI) applications and when we should use them.",
        id: 'xai-apps',
        title: 'Explainable AI (XAI) applications and usage with Joaquin Marques',
        mainImage: 'main.png',
        dateTime: DateFromString("2021-03-30T13:30:00+00:00"),
        length: 1.5,
        content: XaiAppsWebinarContent,
        performer: 'Joaquin Marques'
    },
].map(GenerateUpcoming)


export default webinars;
