<template>
  <div class="webinar-desc">
    <section>
      <h4 class="section-title">About this Event</h4>
      <p class="section-body">
        UPD: In order to take part in the webinar you need to register here. An hour before the
        event you will get an email with a Zoom link.
      </p>
      <p class="section-body">
        Every attendee will be provided with a link to the Zoom event via email and a chance to ask
        a question at a Q&A session at the end of the webinar. For updates, head to the Facebook
        event.
      </p>
      <p class="section-body">
        Webinar hosted by former Chief Data Scientist in Oracle, current AI REV partner. Joaquin
        Marques worked for many years at several of the great centers of research and development of
        AI,\ Natural Language Processing (NPL) and Machine Learning: two spin-offs of Yale AI
        Department, Bell Labs, IBM TJ Watson Labs, and at Experfy, a spin-off of the Harvard
        Incubation Lab.`
      </p>
    </section>
    <section>
      <h4 class="section-title">The following terms will be explored:</h4>
      <p class="section-body">
        How are inherently explainable AI and inherently opaque AI different? Examples The idea of
        black-box vs. glass box vis-a-vis explainable AI and deep learning. What is the
        Interpretability problem, and what are Google’s principles of interpretabilityCurrent
        leading approaches to XAI. Main strategies to interpretability with examples. What is the
        “right to explanation,” and how is it being applied worldwide? Six different contemporary
        approaches to XAI. The XAI Lifecycle. Various XAI services which AIREV provides.
      </p>
      <p class="section-body">The webinar is dedicated for people working in industries such as:</p>
      <ul>
        <li>Healthcare,</li>
        <li>Insurance,</li>
        <li>Finance,</li>
        <li>Legal,</li>
        <li>Human Resources,</li>
        <li>Manufacturing,</li>
        <li>Autonomous Vehicles, etc.</li>
      </ul>
      <p class="section-body">
        It can bring significant value to people in positions such as CEO, CTO, Director of IT/Data
        Analytics, Corporate Risk Officer, Legal Team, etc.
      </p>
    </section>
    <section>
      <h4 class="section-title">About AI REV</h4>
      <p class="section-body">
        AI REV is a <b>boutique partnership</b> of practical AI experts with a strong track record
        who provide state-of-the-art consulting & project delivery for enterprise clients. Each
        Partner has a narrow specialization - in Sales & Marketing, Finance or Healthcare industry.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'XaiAppsWebinarContent',
}
</script>

<style lang="scss">
.webinar-desc {
  .section-title {
    font-size: 18px;
    font-weight: 500;
  }

  .section-body {
    font-size: 18px;
    font-weight: 300;
  }
}
</style>