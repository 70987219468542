











    import 'reflect-metadata';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class DoroImage extends Vue {
        @Prop() public readonly src!: string;

        @Prop() public readonly maxWidth!: string;

        @Prop() public readonly maxHeight!: string;

        @Prop() public readonly square!: boolean;

        @Prop() public readonly simple!: boolean;

        @Prop() public readonly loading!: string;

        @Prop() public readonly inline!: boolean;

        @Prop() public readonly margin!: string;

        @Prop() public readonly imgPadding!: string;

        @Prop() public readonly centeredVertically!: boolean;

        @Prop() public readonly shadow!: boolean;

        @Prop() public readonly hackRendering!: boolean;

        @Prop() public readonly inlineFlex!: boolean;

        @Prop() public readonly spreadVertically!: boolean;

        @Prop() public readonly pixelated!: boolean;
    }
