<template>
  <div class="webinar-desc">
    <section>
      <h4 class="section-title">About this Event</h4>
      <p class="section-body">
        Data is the lifeblood of any organisation, but managing and scaling it as your business grows can be difficult. During this webinar, Matt will talk about practical steps you can follow to get the most out of your data. As practice shows, data is the key to the success of any business. Being able to effectively manage and scale your data can be the difference between success and failure.
      </p>
      <p class="section-body">
        The webinar is dedicated to presenting use cases and best practices for monitoring, scaling, and handling enterprise data to improve business performance. Attendees will learn best practices for monitoring large datasets for trends and insights despite their type and amount of various sources, scaling them to meet performance demands, and how to handle it securely. You'll also learn when to apply artificial intelligence and machine learning to get the most out of your data.
      </p>
      <p class="section-body">
        This webinar is designed for business leaders, including CEOs, COOs, CTOs, and heads of data science and analytics who want to learn how to effectively deal with enterprise data or large scale datasets.
      </p>
    </section>
    <section>
      <h4 class="section-title">About the speaker</h4>
      <p class="section-body">
        A webinar is hosted by Matt Doroszko, who is a partner and engineering manager at AI REV.  He has over a decade of experience in big data, distributed systems, and software engineering. He leads teams that build data-driven products and pipelining infrastructure and helps clients operate and scale their data. He started his adventure with programming at the age of 9 and has been developing his entrepreneurial skills since 2011. He gained his experience working as an AI-Lab Engineer at TikTok, and before that as a Software Engineer developing products and managing teams for American companies.
      </p>
    </section>
    <section>
      <h4 class="section-title">About AI REV</h4>
      <p class="section-body">
        AI REV is a boutique AI consulting company formed with partners having practical AI experience and a strong track record, who provide state-of-the-art consulting & project delivery for enterprise clients. Our goal is to reduce the average work hours of humans from 40h to 20h. We achieve it by augmenting companies with AI-powered efficiency improvements.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HandlingEnterpriseData',
}
</script>

<style lang="scss">
.webinar-desc {
  .section-title {
    font-size: 18px;
    font-weight: 500;
  }

  .section-body {
    font-size: 18px;
    font-weight: 300;
  }
}
</style>
