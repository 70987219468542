import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    posts: [
      {
        name: 'How does AlphaGo work? Power of Reinforcement Learning',
        url: '/how-does-alphago-work',
        category: 'engineering-principles',
      },
    ],
    categories: {
      'engineering-principles': {
        name: 'The best engineering principles',
        url: 'the-best-engineering-principles',
      },
    },
  },
  mutations: {},
  actions: {},
  modules: {},
})
