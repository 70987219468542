import Vue from "vue";
import VueMeta from "vue-meta";
import _ from "loading-attribute-polyfill";
import VueGtag from "vue-gtag";

import VueCodeHighlight from "vue-code-highlight";

import { Row, Col, Container, Header, Main, Footer, Divider } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  attribute: "data-doro-meta",
  refreshOnceOnNavigation: true,
});

Vue.use(VueCodeHighlight);

// Vue.use(Button);
// Vue.use(ButtonGroup);
// Vue.use(Icon, { locale });
Vue.use(Row, { locale });
Vue.use(Col, { locale });
// Vue.use(Progress);
// Vue.use(Spinner);
// Vue.use(Card);
Vue.use(Container, { locale });
Vue.use(Header, { locale });
Vue.use(Main, { locale });
Vue.use(Footer, { locale });
Vue.use(Divider);

Vue.use(
  VueGtag,
  {
    config: { id: "UA-145601237-1" },
  },
  router
);

new Vue({
  router,
  store,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  render: (h) => h(App),
}).$mount("#app");
